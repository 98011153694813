.landing-mobile {

  /*
   /$$   /$$                      /$$
  | $$$ | $$                     | $$
  | $$$$| $$  /$$$$$$  /$$    /$$| $$$$$$$   /$$$$$$   /$$$$$$
  | $$ $$ $$ |____  $$|  $$  /$$/| $$__  $$ |____  $$ /$$__  $$
  | $$  $$$$  /$$$$$$$ \  $$/$$/ | $$  \ $$  /$$$$$$$| $$  \__/
  | $$\  $$$ /$$__  $$  \  $$$/  | $$  | $$ /$$__  $$| $$
  | $$ \  $$|  $$$$$$$   \  $/   | $$$$$$$/|  $$$$$$$| $$
  |__/  \__/ \_______/    \_/    |_______/  \_______/|__/
   */

  .navbar-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px 0;

    .logo svg {
      width: 100px;
    }

    .btn-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-width: 200px;
    }
  }

  .btn-head-mobile {
    background-color: #2394D1;
    border: 2px solid #2394D1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 30px;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
    transition: all .3s ease-out;
    font-weight: bold;
    letter-spacing: 2px;
    font-size: 16px;
  }

  /*
    /$$$$$$  /$$ /$$       /$$
   /$$__  $$| $$|__/      | $$
  | $$  \__/| $$ /$$  /$$$$$$$  /$$$$$$   /$$$$$$
  |  $$$$$$ | $$| $$ /$$__  $$ /$$__  $$ /$$__  $$
   \____  $$| $$| $$| $$  | $$| $$$$$$$$| $$  \__/
   /$$  \ $$| $$| $$| $$  | $$| $$_____/| $$
  |  $$$$$$/| $$| $$|  $$$$$$$|  $$$$$$$| $$
   \______/ |__/|__/ \_______/ \_______/|__/
   */

  .carousel-container {
    width: 100vw;
    height: 100vh;
    box-shadow: inset 3000px 0 0 0 rgba(0, 0, 0, 0.2);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    padding: 200px 0 50px 0;
  }

  h1.header {
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    color: #fff;
    letter-spacing: 3px;
    text-align: center;
  }

  svg.header {
    width: 150px;
  }

  h2.header {
    font-family: 'Comfortaa', sans-serif;
    font-size: 18px;
    color: #fff;
    letter-spacing: 2px;
  }

  .first-block {
    height: 33.3%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .second-block {
    height: 33.3%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .third-block {
    height: 33.3%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  /*
 /$$    /$$ /$$       /$$
| $$   | $$|__/      | $$
| $$   | $$ /$$  /$$$$$$$  /$$$$$$   /$$$$$$
|  $$ / $$/| $$ /$$__  $$ /$$__  $$ /$$__  $$
 \  $$ $$/ | $$| $$  | $$| $$$$$$$$| $$  \ $$
  \  $$$/  | $$| $$  | $$| $$_____/| $$  | $$
   \  $/   | $$|  $$$$$$$|  $$$$$$$|  $$$$$$/
    \_/    |__/ \_______/ \_______/ \______/
 */

  .video-container-mobile {
    width: 100%;
    height: calc(100vw * 9 / 16);
    overflow: hidden;
    position: relative;
  }

  /*
  /$$$$$$$$
  | $$_____/
  | $$        /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$$   /$$$$$$  /$$$$$$/$$$$  /$$   /$$
  | $$$$$    /$$__  $$ /$$__  $$ /$$__  $$| $$__  $$ /$$__  $$| $$_  $$_  $$| $$  | $$
  | $$__/   | $$  \__/| $$  \ $$| $$  \ $$| $$  \ $$| $$  \ $$| $$ \ $$ \ $$| $$  | $$
  | $$      | $$      | $$  | $$| $$  | $$| $$  | $$| $$  | $$| $$ | $$ | $$| $$  | $$
  | $$$$$$$$| $$      |  $$$$$$$|  $$$$$$/| $$  | $$|  $$$$$$/| $$ | $$ | $$|  $$$$$$$
  |________/|__/       \____  $$ \______/ |__/  |__/ \______/ |__/ |__/ |__/ \____  $$
                       /$$  \ $$                                             /$$  | $$
                      |  $$$$$$/                                            |  $$$$$$/
                       \______/                                              \______/
  */

  .ergonomy-mobile-container {
    width: 100%;
    box-shadow: inset 3000px 0 0 0 rgba(0, 0, 0, 0.4);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 85%;
    position: relative;
    height: 740px;

    .ergonomy-content-mobile {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;
      padding: 50px 10px;


      .ergonomy-text-mobile {
        text-align: center;

        h1 {
          font-family: 'Lato', sans-serif;
          color: #FFF848;
          font-size: 40px;
          letter-spacing: 2px;
        }

        .divider-mobile {
          width: 100px;
          height: 6px;
          background-color: #fff;
          margin: 30px auto 60px auto;
        }

        p {
          font-family: 'Comfortaa', sans-serif;
          color: #fff;
          font-size: 18px;
          padding: 0 10px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px;
          text-align: center;
        }
      }

      svg {
        position: absolute;
        width: 110%;
        left: -10px;
      }
    }

    img {
      position: absolute;
      bottom: -150px;
      width: 250px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  /*
    /$$$$$$              /$$
   /$$__  $$            | $$
  | $$  \ $$ /$$   /$$ /$$$$$$    /$$$$$$  /$$$$$$$   /$$$$$$  /$$$$$$/$$$$  /$$   /$$
  | $$$$$$$$| $$  | $$|_  $$_/   /$$__  $$| $$__  $$ /$$__  $$| $$_  $$_  $$| $$  | $$
  | $$__  $$| $$  | $$  | $$    | $$  \ $$| $$  \ $$| $$  \ $$| $$ \ $$ \ $$| $$  | $$
  | $$  | $$| $$  | $$  | $$ /$$| $$  | $$| $$  | $$| $$  | $$| $$ | $$ | $$| $$  | $$
  | $$  | $$|  $$$$$$/  |  $$$$/|  $$$$$$/| $$  | $$|  $$$$$$/| $$ | $$ | $$|  $$$$$$$
  |__/  |__/ \______/    \___/   \______/ |__/  |__/ \______/ |__/ |__/ |__/ \____  $$
                                                                             /$$  | $$
                                                                            |  $$$$$$/
                                                                             \______/
   */

  .autonomy-mobile-container {
    width: 100%;
    padding: 250px 0 300px 0;
    overflow: hidden;
    position: relative;

    h1 {
      font-family: 'Lato', sans-serif;
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: 71px;
      letter-spacing: 0.1em;
      text-align: center;
      color: #000;
    }

    .divider-mobile {
      width: 150px;
      height: 6px;
      background-color: #078DBE;
      margin: 20px auto;
    }

    p {
      font-family: 'Comfortaa', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      text-align: center;
      padding: 0 10px;
    }

    img {
      position: absolute;
      bottom: 0;
      width: 400px;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }

  /*
    /$$$$$$                       /$$$$$$  /$$       /$$                       /$$     /$$           /$$ /$$   /$$
   /$$__  $$                     /$$__  $$|__/      | $$                      | $$    |__/          | $$|__/  | $$
  | $$  \__/  /$$$$$$  /$$$$$$$ | $$  \__/ /$$  /$$$$$$$  /$$$$$$  /$$$$$$$  /$$$$$$   /$$  /$$$$$$ | $$ /$$ /$$$$$$   /$$   /$$
  | $$       /$$__  $$| $$__  $$| $$$$    | $$ /$$__  $$ /$$__  $$| $$__  $$|_  $$_/  | $$ |____  $$| $$| $$|_  $$_/  | $$  | $$
  | $$      | $$  \ $$| $$  \ $$| $$_/    | $$| $$  | $$| $$$$$$$$| $$  \ $$  | $$    | $$  /$$$$$$$| $$| $$  | $$    | $$  | $$
  | $$    $$| $$  | $$| $$  | $$| $$      | $$| $$  | $$| $$_____/| $$  | $$  | $$ /$$| $$ /$$__  $$| $$| $$  | $$ /$$| $$  | $$
  |  $$$$$$/|  $$$$$$/| $$  | $$| $$      | $$|  $$$$$$$|  $$$$$$$| $$  | $$  |  $$$$/| $$|  $$$$$$$| $$| $$  |  $$$$/|  $$$$$$$
   \______/  \______/ |__/  |__/|__/      |__/ \_______/ \_______/|__/  |__/   \___/  |__/ \_______/|__/|__/   \___/   \____  $$
                                                                                                                       /$$  | $$
                                                                                                                      |  $$$$$$/
                                                                                                                       \______/
 */

  .confidentiality-mobile-container {
    width: 100%;
    box-shadow: inset 3000px 0 0 0 rgba(0, 0, 0, 0.4);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 85%;
    position: relative;
    height: 730px;
    padding: 50px 10px;

    h1 {
      font-family: 'Lato', sans-serif;
      font-size: 38px;
      font-style: normal;
      font-weight: 900;
      line-height: 54px;
      letter-spacing: 0.05em;
      color: #FFF848;
      text-align: center;
    }

    .divider-mobile {
      width: 100px;
      height: 6px;
      background-color: #fff;
      margin: 20px auto 50px auto;
    }

    p {
      font-family: 'Comfortaa', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
      text-align: center;
      color: #fff;
    }

    img {
      position: absolute;
      bottom: -200px;
      width: 250px;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 10;
    }
  }


  /*
      /$$$$$$   /$$$$$$   /$$$$$$
     /$$__  $$ /$$__  $$ /$$__  $$
    | $$  \ $$| $$  \__/| $$  \__//$$$$$$   /$$$$$$
    | $$  | $$| $$$$    | $$$$   /$$__  $$ /$$__  $$
    | $$  | $$| $$_/    | $$_/  | $$$$$$$$| $$  \__/
    | $$  | $$| $$      | $$    | $$_____/| $$
    |  $$$$$$/| $$      | $$    |  $$$$$$$| $$
     \______/ |__/      |__/     \_______/|__/
   */

  .offer-mobile-container {
    width: 100%;
    height: 1300px;
    background-color: #EAF5FB;
    position: relative;
    padding: 300px 10px 50px 10px;
    text-align: center;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    h1 {
      font-family: 'Lato', sans-serif;
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: 50px;
      letter-spacing: 0.05em;
      color: #000;
    }

    h1.blue {
      color: #2394D1;
    }

    .divider-mobile {
      width: 150px;
      height: 6px;
      margin: 40px auto;
      background-color: #078DBE;
    }

    p {
      font-family: 'Comfortaa', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px;
    }

    img {
      width: 90%;
      max-width: 599px;
    }
  }


  /*
  /$$$$$$            /$$                                     /$$             /$$     /$$
 /$$__  $$          | $$                                    |__/            | $$    |__/
| $$  \__/ /$$   /$$| $$$$$$$   /$$$$$$$  /$$$$$$$  /$$$$$$  /$$  /$$$$$$  /$$$$$$   /$$  /$$$$$$  /$$$$$$$
|  $$$$$$ | $$  | $$| $$__  $$ /$$_____/ /$$_____/ /$$__  $$| $$ /$$__  $$|_  $$_/  | $$ /$$__  $$| $$__  $$
 \____  $$| $$  | $$| $$  \ $$|  $$$$$$ | $$      | $$  \__/| $$| $$  \ $$  | $$    | $$| $$  \ $$| $$  \ $$
 /$$  \ $$| $$  | $$| $$  | $$ \____  $$| $$      | $$      | $$| $$  | $$  | $$ /$$| $$| $$  | $$| $$  | $$
|  $$$$$$/|  $$$$$$/| $$$$$$$/ /$$$$$$$/|  $$$$$$$| $$      | $$| $$$$$$$/  |  $$$$/| $$|  $$$$$$/| $$  | $$
 \______/  \______/ |_______/ |_______/  \_______/|__/      |__/| $$____/    \___/  |__/ \______/ |__/  |__/
                                                                | $$
                                                                | $$
                                                                |__/
 */

  .subscription-mobile-container {
    width: 100%;
    height: 1200px;
    box-shadow: inset 3000px 0 0 0 rgba(0, 0, 0, 0.4);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 85%;
    position: relative;

    h1 {
      font-family: 'Lato', sans-serif;
      font-size: 36px;
      font-style: normal;
      font-weight: 900;
      line-height: 60px;
      letter-spacing: 0.05em;
      color: #fff;
      text-align: center;
      z-index: 10;
      position: relative;
      padding: 80px 30px 150px 30px;
    }

    .block-form {
      width: 90%;
      height: 632px;
      position: relative;
      margin: auto;

      .block-form-content {
        width: 100%;
        height: 100%;
        background-color: #fff;
        z-index: 11;
        position: relative;
        border-radius: 3px;
        padding: 20px;

        h2 {
          font-family: 'Lato', sans-serif;
          color: #078DBE;
          font-size: 24px;
          font-style: normal;
          font-weight: 900;
          line-height: 30px;
          letter-spacing: 0.01em;
          text-align: center;

        }

        #form-subscription .input {
          background-color: #fff;
          border-radius: 2px;
          border: 1px solid #000;
        }

        #form-subscription .confirm-btn {
          height: 50px;
          border-radius: 2px;
          color: #000;
          background-color: #FCE51C;
          border: 1px solid #FCE51C;
          font-family: 'Lato', sans-serif;
          font-size: 16px;
          font-weight: 900;
          letter-spacing: 0.04em;
          text-transform: uppercase;
        }
      }

      .block-form-duplicate {
        display: none;
      }
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  /*
   /$$$$$$$$                    /$$
  | $$_____/                   | $$
  | $$     /$$$$$$   /$$$$$$  /$$$$$$    /$$$$$$   /$$$$$$
  | $$$$$ /$$__  $$ /$$__  $$|_  $$_/   /$$__  $$ /$$__  $$
  | $$__/| $$  \ $$| $$  \ $$  | $$    | $$$$$$$$| $$  \__/
  | $$   | $$  | $$| $$  | $$  | $$ /$$| $$_____/| $$
  | $$   |  $$$$$$/|  $$$$$$/  |  $$$$/|  $$$$$$$| $$
  |__/    \______/  \______/    \___/   \_______/|__/
   */

  .footer-mobile-container {
    font-family: 'Lato', sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0.06em;
    text-align: center;
    text-transform: uppercase;
    padding: 20px;
  }
}

@media (max-width: 400px) {
  .landing-mobile {

    .navbar-container {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding: 20px 0;

      .logo svg {
        width: 80px;
      }

      .btn-container {
        min-width: 150px;
      }
    }

    .btn-head-mobile {
      background-color: #2394D1;
      border: 2px solid #2394D1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px 20px;
      color: #fff;
      border-radius: 3px;
      cursor: pointer;
      transition: all .3s ease-out;
      font-weight: bold;
      letter-spacing: 2px;
      font-size: 12px;
    }

  }
}