* {
  //margin: 0;
  box-sizing: border-box;
}

.__breakingday__ {
  font-family: "Geomanist", sans-serif;
  font-size: 14px;
  line-height: 1.5;

  .flex {
    display: flex;
  }

  .column {
    flex-direction: column;
  }

  .between {
    justify-content: space-between;
  }

  .wrap {
    flex-wrap: wrap;
  }

  .overflow-auto {
    overflow: auto;
  }

  .smaller {
    font-size: 13px;
    font-weight: lighter;
  }

  .background-grey {
    background-color: #ecedee;
  }

  .relative {
    position: relative;
  }

  .__resaday_open_modal_btn__ {
    position: fixed;
    width: 117px;
    min-height: 117px;
    padding: 15px;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    z-index: 98;
    transition: transform .3s ease-in-out, left .4s linear, right .4s linear;
    box-shadow: 0 0 16px 5px rgba(0, 0, 0, 0.39);

    .brd_title {
      font-weight: bold;
    }

    .icon-widget-btn {
      width: 40px;
      height: 40px;
      margin: 0 auto 10px auto;
      & svg {
        width: 100%;
        height: auto;
      }
    }
  }

  .__resaday_open_modal_btn__:hover {
    transform: scale(1.1);
  }

  .__resaday_overlay_modal__ {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    transition: background-color .3s ease;
    z-index: 100001;

    .__resaday_modal__ {
      max-width: 400px;
      max-height: 700px;
      background-color: rgb(255, 255, 255);
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      box-shadow: 0 4px 12px rgb(0 0 0 / 15%);
      position: relative;
      border-radius: 12px;
      overflow: hidden;

      .simple_next_step_btn {
        width: 90%;
        position: absolute;
        bottom: 20px;
        left: 0;
        right: 0;
        margin: auto;
        z-index: 5;
        text-transform: uppercase;
      }

      .close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        z-index: 10;
      }

      // header
      .__resaday_modal_header__ {
        width: 100%;
        border-radius: 12px;
        position: absolute;
        top: 0;
        z-index: 100;

        .prev {
          position: absolute;
          top: 5px;
          left: 10px;
          cursor: pointer;
        }

        .title {
          font-size: 16px;
          text-align: center;
          padding: 20px 0 0 0;
        }

        .progress_container {
          width: 100%;
          padding: 0 0 10px 0;

          .step {
            width: 90%;
            margin: auto;
            text-align: right;
            line-height: 20px;
          }

          .progress {
            width: 90%;
            height: 10px;
            margin: auto;
            border-radius: 12px;

            .progress_content {
              height: 102%;
              border-radius: 12px;
            }
          }
        }
      }

      // end header

      .__resaday_modal_content__ {
        overflow: auto;

        // Step2
        .timeslots-container {
          padding: 10px 0;

          .timeslot-container {
            width: 32%;
            margin: Calc(4% / 6);

            .timeslot {
              font-size: 11px;
              font-weight: bold;
              padding: 7px 5px;
              border-radius: 12px;
              background-color: #fff;
              width: 100%;
              text-align: center;
              cursor: pointer;
              transition: all .3s ease;
            }
          }
        }

        .offers-container {
          padding: 10px 0;
          transition: .4s all;

          .offer-container {
            width: 50%;
            padding: 2px;

            .offer {
              width: 100%;
              height: 150px;
              cursor: pointer;
              border-radius: 12px;
              border: 3px solid transparent;
              transition: all .3s ease;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 12px;
              }

              .overlay {
                border-radius: 12px;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: black;
                opacity: 0.2;
              }


              .price, .more-info {
                position: absolute;
                top: 10px;
                right: 10px;
                padding: 0 15px;
                border-radius: 12px;
                height: 29px;
                display: flex;
                align-items: center;
                justify-content: center;

                .background {
                  background-color: #fff;
                  opacity: .5;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  border-radius: 12px;
                }

                strong {
                  z-index: 1;
                }
              }

              .more-info {
                left: 10px;
                right: initial;
              }

              .category {
                position: absolute;
                bottom: 10px;
                left: 10px;
                color: #fff;
                word-break: break-word;
                line-height: 14px;
                font-size: 14px;
                font-weight: bold;
              }

              .surface {
                position: absolute;
                bottom: 10px;
                right: 10px;
                color: #fff;
              }
            }
          }
        }

        // end step2

        //carousel

        .carousel-control {
          position: absolute;
          transform: translateY(-50%);
          top: 50%;
          width: 25px;
          height: 25px;
          display: flex;
          align-items: center;
          border-radius: 50%;
          padding: 6px;
          color: white;
          cursor: pointer;
        }

        // calendar
        .react-calendar {

          button {
            font-family: "Geomanist", sans-serif;
            background-color: #fff;
            border: none;
            color: black;
            outline: none;
          }

          .react-calendar__navigation, .react-calendar__month-view__days {
            padding: 5px 10px;
            background-color: #fff;
            border-radius: 12px;
          }

          .react-calendar__navigation {
            button.react-calendar__navigation__arrow {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 35px;
              height: 35px;
              border-radius: 12px;
              background-color: #F7F7F7;
              cursor: pointer;
            }
          }

          .react-calendar__month-view__days {
            padding: 10px;

            button {
              padding: 5px;
              cursor: pointer;
              height: 48.56px;
              transition: all .3s ease;
              border-radius: 50%;
            }

            button:hover, button.react-calendar__tile.react-calendar__tile--active {
              background-color: var(--primary-color);
              color: #fff;
            }

            button[disabled] {
              color: #ccc;
              cursor: auto;
            }

            button[disabled]:hover {
              background-color: #fff;
              color: #ccc;
            }
          }

          .react-calendar__month-view__weekdays {
            padding: 5px 10px;

            .react-calendar__month-view__weekdays__weekday {
              text-align: center;

              abbr[title], abbr[data-original-title] {
                text-decoration: none;
              }
            }
          }
        }

        // end calendar

        .step_content {
          padding: 100px 20px;
          //width: 100%;

          .title {
            font-size: 16px;
            color: black;
            padding-bottom: 20px;
            font-weight: bold;
          }
        }

      }
    }
  }
}

.full {
  width: 100%;
  height: 100%;
}

/*** Derniere étape ***/

.code-button {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 11px;
  width: 70%;
  margin: 24px auto;
  cursor: pointer;

  span {
    font-size: 11px;
    line-height: 161.8%;
  }

  .icon-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    background-color: #2494D1;
    border-radius: 8px;
  }
}

.paiement-info {
  font-size: 14px;
  line-height: 161.8%;
  padding: 0 30px;
  margin-bottom: 35px;
}

.greetings {
  font-size: 16px;
}

.widget-button {
  font-size: 15px;
  color: #2494D1;
  background: #FFFFFF;
  height: 54px;
  border: none;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 8px 16px;
  cursor: pointer;
  align-items: center;
  transition: all .3s ease;

  &[disabled] {
    cursor: not-allowed !important;
  }

  &:focus {
    outline: 0;
  }

  .disabled {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 12px;
    background-color: #bbb;
    opacity: 0.4;
  }
}

/*** Drawer, alert ***/

.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000;
  opacity: .45;
  z-index: 1010;
}

.drawer-content {
  padding: 32px 20px 20px;
  width: 100%;
  background-color: white;
  min-height: 100px;
  transition: all .3s;
  position: fixed;
  bottom: 0;
  z-index: 2000;
  border-radius: 12px;
  max-height: 100%;
  overflow: auto;

  h2 {
    text-align: center;
  }

  &.hidden {
    transform: translateY(100%);
  }
}

.alert-content {
  padding: 32px 20px 20px;
  width: 95%;
  background-color: white;
  min-height: 100px;
  transition: all .3s;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2000;
  border-radius: 12px;
  max-height: 100%;
  overflow: auto;

  h2 {
    text-align: center;
  }

  &.hidden {
    transform: translate(-50%, 100%);
  }
}


/*** Order Details ***/

.order-content, .error-content {
  color: black;

  .title {
    text-align: center;
    margin-bottom: 51px;
  }

  .order-details, .error-details {
    padding-bottom: 25px;
  }

  .order-items {
    padding-bottom: 25px;
  }

  .total {
    font-size: 16px;
    line-height: 161.8%;
    font-weight: bold;
  }

}

.paiement-details {
  font-size: 13px;
  line-height: 161.8%;
  text-align: center;
  font-weight: bold;
  opacity: 0.45;
  margin: 25px;
}

h2 {
  font-size: 16px;
  line-height: 161.8%;
  color: #000000;
  font-weight: 700;
}

/*** Confirmation ***/

.confirm-tab {
  width: 100%;
  background-color: white;
  border-radius: 12px;
  margin-bottom: 24px;

  .confirm-tab-title {
    height: 47px;
    padding: 16px;
    font-size: 13px;
    line-height: 16px;
    text-transform: uppercase;
    color: black;
    position: relative;
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .confirm-tab-content {
    padding: 24px 16px;
    position: relative;

    .tab-line {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      flex-wrap: wrap;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .icon-square {
      width: 38px;
      height: 38px;
      min-width: 38px;
      border-radius: 10px;
      margin-right: 16px;

      position: relative;

      .icon-square-background {
        position: absolute;
        opacity: .15;
        border-radius: 10px;

      }

      div {
        width: 100%;
        height: 100%;
        display: flex;
        //align-items: center;
        justify-content: center;
      }


      svg {
        width: 50%;
        max-width: 16px;
        height: auto;
      }
    }

    .tab-infos {
      width: 45%;
      display: flex;

      .infos {
        display: flex;
        flex-direction: column;
        line-height: 18px;
        font-size: 12px;


        .infos-title {
          font-size: 11px;
          opacity: .45;
          line-height: 16px;
        }

        .service {
          font-size: 10px;
          line-height: 16px;
          letter-spacing: 0.06em;
          text-transform: uppercase;
        }
      }

    }

    .service-infos {
      display: flex;
      flex-direction: column;
      width: Calc(100% / 5);
      align-items: center;

      .icon-square {
        margin: 0;
        width: 50px;
        height: 50px;
        min-width: 38px;
      }

      .infos {
        font-size: 12px;
        color: #B9BACF;
        margin-top: 10px;
        text-align: center;
      }

    }

    .thumb {
      border-radius: 12px;
      height: 90px;
      position: relative;
      width: 100%;
      background-color: rgba(36, 148, 209, 0.15);
      margin-bottom: 16px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 12px;
      }

      .overlay {
        border-radius: 12px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: black;
        opacity: 0.3;
      }

      .infos {
        position: absolute;
        bottom: 10px;
        left: 10px;
        color: white;
        line-height: 1.3em;

        .surface {
          font-size: 0.8em;
        }
      }
    }


  }


}

.confirm-actions {
  position: fixed;
  bottom: 1em;
  width: Calc(100% - 40px);
  display: flex;
  justify-content: space-between;
  margin: 0 20px;

  .total-button {
    color: black;
    display: flex;
    justify-content: space-between;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.25);

    .total {
      display: flex;
      flex-direction: column;
      font-size: 10px;
      text-align: left;
      line-height: 16px;

      .price {
        font-size: 13px;
        line-height: 21px;

      }
    }

    .icon {
      width: 32px;
      height: 32px;
      background-color: rgb(247, 247, 247);
      border-radius: 8px;
      display: flex;
      justify-content: center;
    }
  }
}

/*** input ***/

.input-container {
  position: relative;

  svg {
    max-width: 12px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 24px;
  }
}

.resaday-input {
  border: 1px solid #C7C7C7;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px 16px 44px;
  width: 100%;
  margin-bottom: 10px;
  line-height: 18px;
  font-size: 11px;

  &.error {
    border-color: red;
  }
}

.textArea {
  width: 100%;
  max-width: 100%;
  min-height: 100px;
  border: none;
  resize: none;

  &:focus {
    outline: none;
  }
}

.select-input {
  width: 100%;
  height: 32px;
  padding: 0 11px;
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

  option {
    position: relative;
    display: block;
    min-height: 32px;
    padding: 5px 12px;
    color: #000;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
}

.addon {
  border-radius: 12px;
  background-color: white;
  padding: 16px;
  min-height: 70px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  .tab-infos {
    display: flex;
    align-items: center;

    .icon-square {
      width: 38px;
      height: 38px;
      min-width: 38px;
      border-radius: 10px;
      margin-right: 16px;
      position: relative;

      div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
      }

      svg {
        max-width: 16px;
      }
    }

    .infos {
      display: flex;
      flex-direction: column;
      font-size: 11px;
      line-height: 16px;
      letter-spacing: 0.06em;
      text-transform: uppercase;
      color: black;

    }
  }

}

.background-transparent {
  position: absolute;
  opacity: .15;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 10px;

}

.simplebar-content {
  height: 100%;
}

.simplebar-offset {
  width: 100%;
}


$scrollbarBG: #CFD8DC;
$thumbBG: #90A4AE;
/*** scrollBar for desktop devices ***/
@media (hover: hover) and (pointer: fine) {

  .full, .drawer-content {
    &::-webkit-scrollbar {
      width: 11px;
    }

    scrollbar-width: thin;
    scrollbar-color: $thumbBG $scrollbarBG;

    &::-webkit-scrollbar-track {
      background: $scrollbarBG;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $thumbBG;
      border-radius: 6px;
      border: 3px solid $scrollbarBG;
    }
  }
}

//text selection null

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.block-scroll {
  overflow: hidden;
}

.loader {
  background-color: black;
  opacity: 0.15;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    animation: 4s loading infinite forwards;
    transform: rotateY(0);
  }

}

@keyframes loading {
  50% {
    transform: rotateY(360deg);
  }
}