html {
  scroll-behavior: smooth;
}

.ant-layout-content {
  max-width: 100%;
  overflow: hidden;
}

.uppercase {
  text-transform: uppercase;
}

.modal-video .ant-modal-content {
  background-color: transparent;
}

.landing-desktop {
  width: 100%;
  overflow: hidden;

  /*
    /$$$$$$                                                   /$$
   /$$__  $$                                                 | $$
  | $$  \__/  /$$$$$$  /$$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$ | $$
  | $$ /$$$$ /$$__  $$| $$__  $$ /$$__  $$ /$$__  $$|____  $$| $$
  | $$|_  $$| $$$$$$$$| $$  \ $$| $$$$$$$$| $$  \__/ /$$$$$$$| $$
  | $$  \ $$| $$_____/| $$  | $$| $$_____/| $$      /$$__  $$| $$
  |  $$$$$$/|  $$$$$$$| $$  | $$|  $$$$$$$| $$     |  $$$$$$$| $$
   \______/  \_______/|__/  |__/ \_______/|__/      \_______/|__/
  */

  .align-center {
    text-align: center;
  }

  .align-right {
    text-align: right;
  }

  .btn-head {
    background-color: #2394D1;
    border: 2px solid #2394D1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 30px;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
    transition: all .3s ease-out;
  }

  .btn-head:hover {
    color: #2394D1;
    background-color: #fff;
  }

  .btn-ghost {
    font-size: 16px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    transition: all .3s ease-out;
  }

  /*
   /$$   /$$                      /$$
  | $$$ | $$                     | $$
  | $$$$| $$  /$$$$$$  /$$    /$$| $$$$$$$   /$$$$$$   /$$$$$$
  | $$ $$ $$ |____  $$|  $$  /$$/| $$__  $$ |____  $$ /$$__  $$
  | $$  $$$$  /$$$$$$$ \  $$/$$/ | $$  \ $$  /$$$$$$$| $$  \__/
  | $$\  $$$ /$$__  $$  \  $$$/  | $$  | $$ /$$__  $$| $$
  | $$ \  $$|  $$$$$$$   \  $/   | $$$$$$$/|  $$$$$$$| $$
  |__/  \__/ \_______/    \_/    |_______/  \_______/|__/
   */

  .navbar-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px 100px;
    max-width: 100%;

    .logo {
      svg {
        width: 150px;
      }
    }
  }

  .btn-container {
    display: flex;
    min-width: 600px;
    justify-content: space-around;
    align-items: center;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    letter-spacing: 2px;
  }

  /*
    /$$$$$$  /$$ /$$       /$$
   /$$__  $$| $$|__/      | $$
  | $$  \__/| $$ /$$  /$$$$$$$  /$$$$$$   /$$$$$$
  |  $$$$$$ | $$| $$ /$$__  $$ /$$__  $$ /$$__  $$
   \____  $$| $$| $$| $$  | $$| $$$$$$$$| $$  \__/
   /$$  \ $$| $$| $$| $$  | $$| $$_____/| $$
  |  $$$$$$/| $$| $$|  $$$$$$$|  $$$$$$$| $$
   \______/ |__/|__/ \_______/ \_______/|__/
   */


  .carousel-container {
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    max-height: 100vw;
    display: flex;
    overflow: hidden;
    margin: auto;
    position: relative;
  }

  .carousel-container-block {
    display: flex;
    width: auto;
    position: relative;
    transition: all .3s ease-out;
  }

  .carousel-control {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 6px;
    color: white;
    cursor: pointer;
    background-color: rgba(196, 196, 196, 0.4);
    z-index: 1000000;
  }

  .carousel-image {
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    position: relative;
    box-shadow: inset 3000px 0 0 0 rgba(0, 0, 0, 0.3);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
  }

  .white-wave {
    position: absolute;
    right: 0;
    height: 100vh;
  }

  .first-slide-content {
    max-width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    .first-block {
      padding-top: 150px;
      display: block;
      justify-content: flex-end;
      width: 70%;
      max-width: 800px;
    }

    .second-block {
      width: 30%;
      display: flex;
      justify-content: center;
    }

    .logo-br {
      width: 400px;
    }
  }

  .second-slide-content {
    width: 100%;
    height: 100%;

    .first-block {
      height: 50%;
      display: flex;
      align-items: flex-end;
      padding-left: 400px;
      padding-top: 350px;
    }

    .second-block {
      height: 50%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-right: 200px;
    }
  }

  .third-slide-content {
    max-width: 100%;
    position: relative;
    height: 100%;

    .first-block {
      height: 50%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      text-align: center;
    }

    .second-block {
      height: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 22px;
  }

  p {
    font-size: 18px;
  }

  p.small {
    font-size: 12px;
  }

  h1.header {
    font-family: 'Lato', sans-serif;
    color: #fff;
    letter-spacing: 3px;
  }

  h2.header {
    color: #fff;
    font-family: 'Comfortaa', sans-serif;
    text-align: center;
  }

  /*
   /$$    /$$ /$$       /$$
  | $$   | $$|__/      | $$
  | $$   | $$ /$$  /$$$$$$$  /$$$$$$   /$$$$$$
  |  $$ / $$/| $$ /$$__  $$ /$$__  $$ /$$__  $$
   \  $$ $$/ | $$| $$  | $$| $$$$$$$$| $$  \ $$
    \  $$$/  | $$| $$  | $$| $$_____/| $$  | $$
     \  $/   | $$|  $$$$$$$|  $$$$$$$|  $$$$$$/
      \_/    |__/ \_______/ \_______/ \______/
   */

  .video-container {
    width: 100%;
    height: calc(100vw * 9 / 16);
    max-height: 85vh;
    overflow: hidden;
    position: relative;
    display: flex;
    align-items: center;
    background-color: #eaf5fb;

    img {
      width: 100%;
    }

    img:hover, svg:hover {
      cursor: pointer;
    }

    .video-iframe-container {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      padding-bottom: 56.25%;

      iframe {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        border: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

      }
    }

    #overlay-video {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 100;
    }

  }

  /*
    /$$$$$$$$
  | $$_____/
  | $$        /$$$$$$   /$$$$$$   /$$$$$$  /$$$$$$$   /$$$$$$  /$$$$$$/$$$$  /$$   /$$
  | $$$$$    /$$__  $$ /$$__  $$ /$$__  $$| $$__  $$ /$$__  $$| $$_  $$_  $$| $$  | $$
  | $$__/   | $$  \__/| $$  \ $$| $$  \ $$| $$  \ $$| $$  \ $$| $$ \ $$ \ $$| $$  | $$
  | $$      | $$      | $$  | $$| $$  | $$| $$  | $$| $$  | $$| $$ | $$ | $$| $$  | $$
  | $$$$$$$$| $$      |  $$$$$$$|  $$$$$$/| $$  | $$|  $$$$$$/| $$ | $$ | $$|  $$$$$$$
  |________/|__/       \____  $$ \______/ |__/  |__/ \______/ |__/ |__/ |__/ \____  $$
                       /$$  \ $$                                             /$$  | $$
                      |  $$$$$$/                                            |  $$$$$$/
                       \______/                                              \______/
 */

  .ergonomy-container {
    width: 100%;
    box-shadow: inset 3000px 0 0 0 rgba(0, 0, 0, 0.2);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 85%;
    position: relative;
    height: 602px;

    .ergonomy-content {
      padding: 100px 10%;
      display: flex;
      justify-content: flex-end;
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 100%;

      svg {
        position: absolute;
        top: -2px;
        right: 0;
      }
    }

    .ergonomy-text {
      z-index: 10;
    }

    h1 {
      font-family: 'Lato', sans-serif;
      color: #FFF848;
      text-align: center;
    }

    .divider {
      width: 300px;
      height: 6px;
      background-color: #fff;
      margin: 20px auto;
    }

    p {
      color: #fff;
      font-family: 'Comfortaa', sans-serif;
      text-align: center;
    }

    img {
      position: absolute;
      width: 400px;
      top: 50px;
      left: 10%;
      z-index: 10;
    }
  }

  /*
    /$$$$$$              /$$
   /$$__  $$            | $$
  | $$  \ $$ /$$   /$$ /$$$$$$    /$$$$$$  /$$$$$$$   /$$$$$$  /$$$$$$/$$$$  /$$   /$$
  | $$$$$$$$| $$  | $$|_  $$_/   /$$__  $$| $$__  $$ /$$__  $$| $$_  $$_  $$| $$  | $$
  | $$__  $$| $$  | $$  | $$    | $$  \ $$| $$  \ $$| $$  \ $$| $$ \ $$ \ $$| $$  | $$
  | $$  | $$| $$  | $$  | $$ /$$| $$  | $$| $$  | $$| $$  | $$| $$ | $$ | $$| $$  | $$
  | $$  | $$|  $$$$$$/  |  $$$$/|  $$$$$$/| $$  | $$|  $$$$$$/| $$ | $$ | $$|  $$$$$$$
  |__/  |__/ \______/    \___/   \______/ |__/  |__/ \______/ |__/ |__/ |__/ \____  $$
                                                                             /$$  | $$
                                                                            |  $$$$$$/
                                                                             \______/
   */

  .autonomy-container {
    width: 100%;
    display: flex;
    position: relative;
    overflow: hidden;
    padding: 200px 0;

    .first-block {
      width: 50%;
      z-index: 10;
    }

    .second-block {
      width: 50%;
      position: relative;
      z-index: 10;

      img {
        position: absolute;
        right: -300px;
        width: 1000px;
      }
    }

    h1 {
      font-family: 'Lato', sans-serif;
      color: #000;
    }

    .divider {
      width: 200px;
      height: 6px;
      background-color: #2394D1;
      margin: 20px 0 50px 0;
    }

    p {
      color: #000;
      font-family: 'Comfortaa', sans-serif;
    }

    svg {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
    }

  }

  /*
      /$$$$$$                       /$$$$$$  /$$       /$$                       /$$     /$$           /$$ /$$   /$$
     /$$__  $$                     /$$__  $$|__/      | $$                      | $$    |__/          | $$|__/  | $$
    | $$  \__/  /$$$$$$  /$$$$$$$ | $$  \__/ /$$  /$$$$$$$  /$$$$$$  /$$$$$$$  /$$$$$$   /$$  /$$$$$$ | $$ /$$ /$$$$$$   /$$   /$$
    | $$       /$$__  $$| $$__  $$| $$$$    | $$ /$$__  $$ /$$__  $$| $$__  $$|_  $$_/  | $$ |____  $$| $$| $$|_  $$_/  | $$  | $$
    | $$      | $$  \ $$| $$  \ $$| $$_/    | $$| $$  | $$| $$$$$$$$| $$  \ $$  | $$    | $$  /$$$$$$$| $$| $$  | $$    | $$  | $$
    | $$    $$| $$  | $$| $$  | $$| $$      | $$| $$  | $$| $$_____/| $$  | $$  | $$ /$$| $$ /$$__  $$| $$| $$  | $$ /$$| $$  | $$
    |  $$$$$$/|  $$$$$$/| $$  | $$| $$      | $$|  $$$$$$$|  $$$$$$$| $$  | $$  |  $$$$/| $$|  $$$$$$$| $$| $$  |  $$$$/|  $$$$$$$
     \______/  \______/ |__/  |__/|__/      |__/ \_______/ \_______/|__/  |__/   \___/  |__/ \_______/|__/|__/   \___/   \____  $$
                                                                                                                         /$$  | $$
                                                                                                                        |  $$$$$$/
                                                                                                                         \______/
   */

  .confidentiality-container {
    width: 100%;
    box-shadow: inset 3000px 0 0 0 rgba(0, 0, 0, 0.2);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 85%;
    position: relative;
    height: 600px;

    .confidentiality-content {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 10%;

      h1 {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 128px;
        letter-spacing: 0.05em;
        text-align: center;
        color: #FFF848;
      }

      .divider {
        width: 370px;
        height: 6px;
        margin: 20px auto;
        background-color: #fff;
      }

      p {
        font-family: 'Comfortaa', sans-serif;
        font-style: normal;
        font-weight: 700;
        line-height: 46px;
        text-align: center;
        color: #fff;
      }

      svg {
        position: absolute;
        right: 0;
      }
    }

    img {
      position: absolute;
      width: 400px;
      top: -20px;
      left: 10%;
      z-index: 10;
    }
  }

  /*
      /$$$$$$   /$$$$$$   /$$$$$$
     /$$__  $$ /$$__  $$ /$$__  $$
    | $$  \ $$| $$  \__/| $$  \__//$$$$$$   /$$$$$$
    | $$  | $$| $$$$    | $$$$   /$$__  $$ /$$__  $$
    | $$  | $$| $$_/    | $$_/  | $$$$$$$$| $$  \__/
    | $$  | $$| $$      | $$    | $$_____/| $$
    |  $$$$$$/| $$      | $$    |  $$$$$$$| $$
     \______/ |__/      |__/     \_______/|__/
   */

  .offer-container {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 10%;
    height: 1000px;
    background-color: #EAF5FB;
    overflow: hidden;

    h1 {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 900;
      line-height: 80px;
      letter-spacing: 0.05em;
      text-align: left;
      color: #000;
    }

    h1.blue {
      color: #2394D1;
    }

    .divider {
      width: 300px;
      height: 6px;
      margin: 40px 0;
      background-color: #2394D1;
    }

    p {
      font-family: 'Comfortaa', sans-serif;
      font-style: normal;
      font-weight: 700;
      line-height: 46px;
      text-align: left;
    }

    img {
      position: absolute;
      width: 900px;
      right: -50px;
      border-radius: 50%;
    }

  }

  /*
    /$$$$$$            /$$                                     /$$             /$$     /$$
   /$$__  $$          | $$                                    |__/            | $$    |__/
  | $$  \__/ /$$   /$$| $$$$$$$   /$$$$$$$  /$$$$$$$  /$$$$$$  /$$  /$$$$$$  /$$$$$$   /$$  /$$$$$$  /$$$$$$$
  |  $$$$$$ | $$  | $$| $$__  $$ /$$_____/ /$$_____/ /$$__  $$| $$ /$$__  $$|_  $$_/  | $$ /$$__  $$| $$__  $$
   \____  $$| $$  | $$| $$  \ $$|  $$$$$$ | $$      | $$  \__/| $$| $$  \ $$  | $$    | $$| $$  \ $$| $$  \ $$
   /$$  \ $$| $$  | $$| $$  | $$ \____  $$| $$      | $$      | $$| $$  | $$  | $$ /$$| $$| $$  | $$| $$  | $$
  |  $$$$$$/|  $$$$$$/| $$$$$$$/ /$$$$$$$/|  $$$$$$$| $$      | $$| $$$$$$$/  |  $$$$/| $$|  $$$$$$/| $$  | $$
   \______/  \______/ |_______/ |_______/  \_______/|__/      |__/| $$____/    \___/  |__/ \______/ |__/  |__/
                                                                  | $$
                                                                  | $$
                                                                  |__/
   */

  .subscription-container {
    width: 100%;
    height: 760px;
    box-shadow: inset 3000px 0 0 0 rgba(0, 0, 0, 0.2);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 85%;
    position: relative;
    display: flex;
    overflow: hidden;

    .first-block {
      width: 50%;
      padding-top: 100px;
      display: flex;
      justify-content: center;

      h1 {
        z-index: 10;
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 900;
        line-height: 108px;
        letter-spacing: 0.05em;
        text-align: left;
        color: white;
        width: 366px;
      }
    }

    .second-block {
      width: 50%;
      padding-top: 90px;

      .block-form {
        width: 477px;
        height: 632px;
        position: relative;

        .block-form-content {
          width: 100%;
          height: 100%;
          background-color: #fff;
          z-index: 11;
          position: relative;
          border-radius: 3px;
          padding: 20px;

          h2 {
            font-family: 'Lato', sans-serif;
            color: #078DBE;
            font-style: normal;
            font-weight: 900;
            line-height: 69px;
            letter-spacing: 0.01em;
            text-align: center;

          }

          #form-subscription .input {
            background-color: #fff;
            border-radius: 2px;
            border: 1px solid #000;
          }

          #form-subscription .confirm-btn {
            height: 50px;
            border-radius: 2px;
            color: #000;
            background-color: #FCE51C;
            border: 1px solid #FCE51C;
            font-family: 'Lato', sans-serif;
            font-size: 18px;
            font-weight: 900;
            letter-spacing: 0.06em;
            text-transform: uppercase;
          }
        }

        .block-form-duplicate {
          position: absolute;
          width: 477px;
          height: 632px;
          border: 5px solid #fff;
          bottom: 50px;
          left: 80px;
          z-index: 10;
        }
      }
    }

    svg {
      z-index: 9;
      position: absolute;
      top: 0;
      left: 0;
    }
  }


  /*
   /$$$$$$$$                    /$$
  | $$_____/                   | $$
  | $$     /$$$$$$   /$$$$$$  /$$$$$$    /$$$$$$   /$$$$$$
  | $$$$$ /$$__  $$ /$$__  $$|_  $$_/   /$$__  $$ /$$__  $$
  | $$__/| $$  \ $$| $$  \ $$  | $$    | $$$$$$$$| $$  \__/
  | $$   | $$  | $$| $$  | $$  | $$ /$$| $$_____/| $$
  | $$   |  $$$$$$/|  $$$$$$/  |  $$$$/|  $$$$$$$| $$
  |__/    \______/  \______/    \___/   \_______/|__/
   */

  .footer-container {
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0.06em;
    text-align: center;
    padding: 36px 20px 20px 20px;
  }
}

@media (max-width: 1600px) {
  .landing-desktop {
    .first-slide-content {
      justify-content: space-around;

      .first-block {
        width: 60%;
      }

      .second-block {
        width: 40%;
      }

      .logo-br {
        width: 300px;
      }
    }

    .second-slide-content {
      .first-block {
        padding-left: 300px;
      }

      .second-block {
      }
    }

    .offer-container {

      img {
        width: 800px;
      }

    }
  }
}

@media (max-width: 1400px) {
  .landing-desktop {
    .first-slide-content {

      justify-content: space-around;

      .first-block {
        width: 50%;
      }

      .second-block {
        width: 50%;
      }

      .logo-br {
        width: 250px;
      }
    }

    .second-slide-content {
      .first-block {
        padding-left: 200px;
      }

      .second-block {
      }
    }

    .video-container {

      iframe {
        width: 100%;
        height: 100%;
      }
    }

    .offer-container {

      img {
        width: 700px;
      }

    }
  }
}

@media (max-width: 1200px) {
  .landing-desktop {

    .autonomy-container {

      .second-block {

        img {
          right: -500px;
        }
      }
    }

    .ergonomy-container {
      img {
        width: 300px;
      }
    }

    .confidentiality-container {
      img {
        width: 300px;
      }
    }

    .offer-container {

      img {
        width: 600px;
      }

    }

  }
}

@media (max-width: 1000px) {
  .landing-desktop {

    .navbar-container {
      padding: 40px 50px;
      min-width: 500px;

      .logo {
        svg {
          width: 100px;
        }
      }
    }

    .ergonomy-container {
      img {
        width: 200px;
        left: 5%;
        top: 20%;
      }
    }

    .confidentiality-container {
      img {
        width: 200px;
        left: 5%;
        top: 20%;
      }
    }

    .offer-container {

      img {
        width: 500px;
      }

    }

  }
}

