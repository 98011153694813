.widget {
  @import "widget";
}

#root {
  height: 100%;
}

.site-calendar-customize-header-wrapper {
  width: 100%;
  padding: 8px;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
}

.flag-select {
  border: solid 2px #e8e8e8;
  cursor: pointer;
  display: inline-block;
  padding: 1px 10px;
  opacity: .5;
  position: relative;

  &.selected-lng {
    border-color: #2494D1;
    opacity: 1;
  }

  &:hover {
    opacity: 1;
  }

  & .default {
    position: absolute;
    top: -6px;
    font-weight: bold;
    right: -1px;
    color: #E64D3D;
    font-size: 20px;
  }
}

.relative {
  position: relative;
}

.text-center {
  text-align: center;
}

.flex {
  display: flex;
}

.flexColumn {
  flex-direction: column;
}

.wrap {
  flex-wrap: wrap;
}

.between {
  justify-content: space-between;
}

.around {
  justify-content: space-around;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.align-baseline {
  align-items: baseline;
}

.align-stretch {
  align-items: stretch;
}

.m-10 {
  margin: 10px;
}

.m-1em {
  margin: 2rem;
}

.mb-1em {
  margin-bottom: 1em;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-2em {
  margin-top: 2rem;
}

.p-10 {
  padding: 10px;
}

.radius-5 {
  border-radius: 5px;
}

.pointer {
  cursor: pointer;
}

.resaday_badge {
  position: absolute;
  top: -5px;
  left: 15px;
  background-color: #FF9900;
  width: 15px;
  height: 15px;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: white;
  cursor: pointer;
  z-index: 10;
}

.badge_status {
  color: #fff;
  font-size: 10px;
  padding: 3px 10px;
  border-radius: 10px;
  cursor: pointer;
}

.site-layout-background {
  background: #fff;
}

#div {
  border: 1px dotted #000;
  position: absolute;
  z-index: 11000;
  background-color: rgba(150, 150, 150, 0.3);
}

.checkbox-group-vertical {
  .ant-checkbox-group-item {
    display: block;
  }

  .ant-checkbox {
    display: inline-block;
  }
}

/*** Titles ***/

h1 {
  color: #2494D1;
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  line-height: 161.8%;
  margin-bottom: 0;
}

h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 161.8%;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 161.8%;
}

h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 161.8%;
}

.ant-page-header-heading-extra {
  display: flex;
  align-items: center;
}

.selected-view {
  border-color: #F0F0F0;
  background-color: #F0F0F0;
  color: #2494D1;
}

/*** Header Menu ***/

.ant-layout-header.header {
  z-index: 11;
  display: flex;
  justify-content: space-between;
  height: 84px;
  padding: 0 84px;
  box-shadow: 0 4px 16px rgb(0 0 0 / 25%);
}

.header .ant-menu-item a {
  font-weight: bold;
  font-size: 15px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  line-height: 24px;
  color: white;
}

.ant-menu-item-selected {
  text-decoration: underline;
  text-underline-offset: 12px;
  text-decoration-thickness: 2px;
  border: none !important;
}

/*** account card ***/

.account-card-grid {
  margin: 2em 0;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, 350px);
  justify-content: center;
}

.login-form-container {
  justify-content: center;
  align-items: center;
  padding: 0 84px;
  height: 100%;
  display: flex;
  min-height: 100vh;
}

.card-style {
  box-shadow: 0 4px 16px rgb(0 0 0 / 25%);
  border-radius: 8px;
  width: 550px;
  margin: 0;
}

/*** Price Table ***/


.price-table-options {
  margin: 1em 0;
  display: flex;
  justify-content: space-between;
}


.table-cell {
  background-color: white;

  &.weekend {
    background-color: #fafafa;
  }
}

.selections {
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  max-width: 100%;
  overflow: auto;
  background-color: #fafafa;
}

.header-table {
  table-layout: fixed;
  width: 100%;

  & tr td:first-child, tr th:first-child {
    border-right: #e8e8e8 2px solid;
    width: 120px;
    padding: 16px 5px;
    background-color: #fafafa;
    position: sticky;
    left: 0;
    z-index: 3;
  }
}

.header-cell {
  min-width: 50px;
  max-width: 50px;
  padding: 16px 8px;
  text-align: center;
  border-right: 1px solid #e8e8e8;
}

.table-row {
  border-bottom: 1px solid #e8e8e8;
}

.category-cell {
  background-color: #fafafa;
  padding: 8px 16px;
  position: sticky;
  left: 0;
  color: #2494D1;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid #e8e8e8;


  & span {
    max-width: 250px;
  }
}

.cell {
  font-size: 12px;
}

.selected {
  background-color: turquoise;
}

.selection-area {
  background: rgba(46, 115, 252, 0.11);
  border: 2px solid rgba(98, 155, 255, 0.81);
  border-radius: 0.1em;
}


/*** booking tab ***/

.booking-tab {
  th, td {
    border: 1px solid #e8e8e8;
  }
}

/*** Services ***/
.icon {
  width: 40px;
  height: 40px;
  border: solid 1px #8d8d8d;
  justify-content: center;
  align-items: center;
  display: flex;
}

.service-card {
  width: 100px;
  height: 100px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #e8e8e8;
  padding: 15px;
  text-align: center;
  justify-content: space-between;

  & .service-icon {
    max-width: 30px;
    max-height: 30px;
    color: #2494D1;
    fill: #2494D1;

    & svg {
      width: 100%;
      height: auto;
      max-height: 30px;
    }
  }
}

/*** Categories ***/

.delete-icon {
  position: absolute;
  width: 20px;
  height: 20px;
  align-items: center;
  z-index: 2;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  color: #E64D3D;
}

.hapiday-carousel-chevron {
  position: absolute;
  background-color: #ccc;
  padding: 10px;
  border-radius: 50%;
  transition: background-color .3s ease-in-out;
}

.hapiday-carousel-chevron:hover {
  background-color: #aaa;
}


.select-option {
  & svg {
    height: auto;
    width: 100%
  }
}

/*** Color ***/


.color-container {
  width: 32px;
  height: 32px;
}


.colorSquare {
  border-radius: 5px;
  border: 1px solid rgb(190 190 190 / 80%);
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
}

.colorPicker {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 32px;
  height: 32px;
}

.transparent-bg {
  background: #eee;
  background-image: linear-gradient(45deg, rgba(0, 0, 0, .25) 25%, transparent 0, transparent 75%, rgba(0, 0, 0, .25) 0),
  linear-gradient(45deg, rgba(0, 0, 0, .25) 25%, transparent 0, transparent 75%, rgba(0, 0, 0, .25) 0);
  background-position: 0 0, 16px 16px;
  background-size: 32px 32px;
}

// acountsettings

.icon-widget-btn {
  width: 40px;
  height: 40px;

  & svg {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 700px) {
  .login-form-container {
    padding: 0;
  }
  .card-style {
    width: 95%;
    padding: 0;
    margin: auto;
  }

}
